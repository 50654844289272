import React from "react";
import "./PageNotFound.scss";

const PageNotFound: React.FC = () => {

  return (
    <div>Page Not Found: 404</div>
  )
}

export default PageNotFound;
